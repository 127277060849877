// serializers
import {
    MainFontSerializer,
} from 'serializers/site'

// data
import {
    media_url_site,
    media_version_site,
} from 'data'

// main
export function getGoogleFontsSite(fonts: MainFontSerializer[] | undefined) {
    const customFontArray: string[] = []
    const customFontUrls: string[] = []
    const googlFontArray: string[] = []
    if (fonts?.length! > 0) {
        fonts?.map((val) => {
            if (val.category === 'manual') {
                customFontArray.push(`${val.name}:`)
                // customFontUrls.push(`https://media.justtheweb.io/global/fonts/${val.name}/stylesheet.css${media_version_site}`)
                customFontUrls.push(`https://s3.eu-west-3.amazonaws.com/justtheweb.io/global/fonts/${val.name}/stylesheet.css${media_version_site}`)
            } else {
                googlFontArray.push(`${val.name}:wght@100;200;300;400;500;600;700;800;900`)
            }
        })
    }
    return {
        'custom': customFontArray,
        'customUrls': customFontUrls,
        'google': googlFontArray,
    }
}
