// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    api_url_shipping_country,
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getAxiosHeadersSite,
} from 'services'

// props
type MondialRelayWidgetSiteProps = {
    getShippingCost: any
    setShipping_info: any
    shipping_info: any
}

// main
export const MondialRelayWidgetSite: React.FC<MondialRelayWidgetSiteProps> = React.memo(({
    getShippingCost,
    setShipping_info,
    shipping_info,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [countries, setCountries] = useState<any[]>([])

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // console.log('Received message', event.data?.selectedRelayPointData)
            if (event.data?.selectedRelayPointData) {
                const country = countries.find((country) => country.country === event.data.selectedRelayPointData.country)
                setShipping_info({
                    ...shipping_info,
                    address: event.data.selectedRelayPointData.address,
                    city: event.data.selectedRelayPointData.city,
                    country: country,
                    relay_point_id: `${event.data.selectedRelayPointData.country}-${event.data.selectedRelayPointData.id}`,
                    relay_point_name: event.data.selectedRelayPointData.name,
                    zip_code: event.data.selectedRelayPointData.postalCode,
                })
                getShippingCost(country.id, 17)
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [
        countries,
    ])

    useEffect(() => {
        getCountries()
    }, [])

    function getCountries() {
        try {
            const getUrl = getApiUrlSite(`${api_url_shipping_country}?portfolio=${reduxCacheSite.portfolio?.id}`, reduxModalSite)
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setCountries(response.data)
                })
                .catch((error) => {
                    axiosErrorHandlerSite({
                        apiUrl: getUrl,
                        component: 'MondialRelayWidgetSite',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'getCountries',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'MondialRelayWidgetSite',
                'getCountries',
            ))
        }
    }

    if (!countries.length) return null

    return (
        <iframe
            src="https://s3.eu-west-3.amazonaws.com/justtheweb.io/widgets/mondial-relay-widget.html??v32&brand=CC23BVO1"
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Mondial Relay Widget"
        />
    )
})
