// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    ListSpinnerSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    ContentBlockSite,
} from 'pages'

// serializers
import {
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getMixedContentDataSite,
    getPageContentDataSite,
    getHubContentDetailInfiniteSite,
} from 'services'

// props
type InfiniteBlockProps = {
    contentIdsAll?: any[]
    detailId: string | undefined
    devId: string | undefined
    hubComponentType?: string
    hubContentSlug?: string
    inAbout?: any
    infiniteContentId: number
    isButton: boolean
    isRandomOrdering: boolean
    linkColor: string | undefined
    pageNumberNew: number
    pageSlug: string | undefined
}

// main
export const InfiniteBlock: React.FC<InfiniteBlockProps> = React.memo(({
    contentIdsAll,
    detailId,
    devId,
    hubComponentType,
    hubContentSlug,
    inAbout,
    infiniteContentId,
    isButton,
    isRandomOrdering,
    linkColor,
    pageNumberNew,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(true)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false)
    const [currentLocation, setCurrentLocation] = useState<any>(location)

    useEffect(() => {
        if (currentLocation !== location) {
            setIsLoading(true)
            setPageContentItems(undefined)
            setDisableInfiniteScroll(false)
            setCurrentLocation(location)
        }
    }, [
        location,
    ])

    function getPortfolioData() {
        try {
            if (!inAbout && (pageSlug === 'custom-page-detail' || !reduxCacheSite.portfolio)) {
                getMixedContentDataSite({
                    component: 'InfiniteBlock',
                    dispatch,
                    infiniteContentId,
                    pageNumberNew,
                    reduxAuth,
                    reduxModalSite,
                    setIsLoading,
                    setItems: setPageContentItems,
                })
            } else if (reduxCacheSite.portfolio || inAbout) {
                setDisableInfiniteScroll(true)
                if (hubContentSlug && hubComponentType) {
                    getHubContentDetailInfiniteSite({
                        component: 'InfiniteBlock',
                        componentType: hubComponentType,
                        contentSlug: hubContentSlug,
                        dispatch,
                        location,
                        pageNumberNew,
                        portfolioId: `${reduxCacheSite.portfolio?.id}`,
                        reduxAuth,
                        reduxModalSite,
                        setIsLoading,
                        setPageContentItems,
                    })
                } else {
                    getPageContentDataSite({
                        axiosCancelToken: undefined,
                        component: 'InfiniteBlock',
                        detailId,
                        devId,
                        dispatch,
                        errorStatus,
                        infiniteContentId,
                        isRandomOrdering,
                        location,
                        pageContentItems: contentIdsAll,
                        pageNumberNew,
                        pageSlug: pageSlug || reduxCacheSite.portfolio?.home_page_slug! || inAbout?.home_page_slug,
                        portfolioObject: reduxCacheSite.portfolio || inAbout,
                        reduxAuth,
                        reduxCacheSite,
                        reduxModalSite,
                        setErrorStatus,
                        setIsLoading,
                        setPageContentItems,
                    })
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteBlock',
                'getPortfolioData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                getPortfolioData()
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'InfiniteBlock',
                'onSearchNext',
            ))
        }
    }

    return (
        <React.Fragment>
            {isLoading && (!pageContentItems || (pageContentItems?.length === 0)) && (
                isButton
                    ? (
                        <p
                            onClick={() => onSearchNext(true)}
                            style={{
                                color: reduxCacheSite.portfolio?.id === 661 ? 'rgb(250, 232, 112)' : 'rgb(0, 88, 132)',
                                cursor: 'pointer',
                                fontSize: '40px',
                                fontWeight: 'bold',
                                paddingBottom: '50px',
                                textAlign: 'center',
                                textDecoration: 'underline',
                                textTransform: 'uppercase',
                                width: '100%',
                            }}
                        >
                            {reduxText[8021]}
                        </p>
                    ) : (
                        <VisibilitySensor
                            active={!disableInfiniteScroll}
                            intervalDelay={1000}
                            offset={{ bottom: -1000 }}
                            onChange={onSearchNext}
                            partialVisibility
                        >
                            <div className='infinite-scroll-helper-site-empty'>
                                <ListSpinnerSite isLoading />
                            </div>
                        </VisibilitySensor>
                    )
            )}
            {pageContentItems?.filter(obj => obj.active === true).map((val) => (
                <ContentBlockSite
                    key={val.id}
                    contentIdsAll={contentIdsAll}
                    detailId={detailId}
                    devId={devId}
                    hubComponentType={hubComponentType}
                    hubContentSlug={hubContentSlug}
                    infiniteContentId={infiniteContentId}
                    isEditHovered={false}
                    linkColor={linkColor}
                    object={val}
                    pageNumberNew={pageNumberNew}
                    pageSlug={pageSlug || reduxCacheSite.portfolio?.home_page_slug}
                    stylesEdit={undefined}
                />
            ))}
        </React.Fragment>
    )
})
