// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    Button,
    Snackbar,
} from '@mui/material'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    defaultReduxState,
    groupingIdsSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSiteV2,
    getAxiosHeadersSite,
    getStylesNew,
    getStylesToRetrieveNew,
} from 'services'

import * as templateBlock from './index'

// props
type TemplateBlock796SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    inFormModal?: boolean
    inListIndex?: number
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock796Site: React.FC<TemplateBlock796SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    inFormModal,
    inListIndex,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteparentContent = useSelector((state: defaultReduxState) => state.reduxFormSite.parentContent)
    const reduxFormSitemodalForm = useSelector((state: defaultReduxState) => state.reduxFormSite.modalForm)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [fields, setFields] = useState<any>([
        {
            'label': reduxText[7613],
            'name': 'name',
            'placeholder': 'false',
            'field_type': 'text',
        },
        {
            'label': reduxText[7477],
            'name': 'email',
            'placeholder': 'false',
            'field_type': 'text',
        },
        {
            'label': reduxText[7614],
            'name': 'message',
            'placeholder': 'false',
            'field_type': 'textarea',
        },
    ])
    const [formValue, setFormValue] = useState<formValueTypeSite>({ errors: {}, fields: {}, helpers: { buttonDisabled: true } })
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
        applyFields()
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock796Site',
                'applyStyles',
            ))
        }
    }

    function applyFields() {
        try {
            const placeholder = getStylesToRetrieveNew(reduxModalSite, styles, stylesEdit, 'placeholder') || 'false'
            if (object.layout_type === 'e') {
                if (object.data_json?.newsletterMergeFields) {
                    const mergeFields: any[] = []
                    object.data_json.newsletterMergeFields.map((val) => {
                        mergeFields.push({
                            'label': val.name_1,
                            'name': val.tag,
                            'placeholder': placeholder,
                            'field_type': val.tag === 'EMAIL' ? 'email' : 'text',
                        })
                    })
                    setFields(mergeFields)
                } else if (object.form_fields?.length! > 0) {
                    const formFields: any[] = []
                    object.form_fields!.map((val) => {
                        // @ts-ignore
                        val.placeholder = placeholder
                        formFields.push(val)
                    })
                    setFields(formFields)
                    if (reduxFormSitemodalForm?.objectId) {
                        getDetailData(formFields)
                    }
                } else {
                    setFields([
                        {
                            'label': reduxText[7477],
                            'name': 'EMAIL',
                            'placeholder': placeholder,
                            'field_type': 'email',
                        },
                    ])
                }
            } else if (object.form_fields?.length! > 0) {
                const formFields: any[] = []
                object.form_fields!.map((val) => {
                    // @ts-ignore
                    val.placeholder = placeholder
                    formFields.push(val)
                })
                setFields(formFields)
                if (reduxFormSitemodalForm?.objectId) {
                    getDetailData(formFields)
                }
            } else {
                setFields([
                    {
                        'label': reduxText[7613],
                        'name': 'name',
                        'placeholder': placeholder,
                        'required': true,
                        'field_type': 'text',
                    },
                    {
                        'label': reduxText[7477],
                        'name': 'email',
                        'placeholder': placeholder,
                        'required': true,
                        'field_type': 'email',
                    },
                    {
                        'label': reduxText[7614],
                        'name': 'message',
                        'placeholder': placeholder,
                        'required': true,
                        'field_type': 'textarea',
                    },
                ])
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock796Site',
                'applyFields',
            ))
        }
    }

    function getDetailData(formFields: any) {
        try {
            setIsLoadingData(true)
            const contentType = reduxFormSitemodalForm?.contentType
            const app = contentType?.split('_')[0]
            const model = contentType?.split('_')[1]
            const getUrl = getApiUrlSiteV2(`${app}/form/${model}/detail/${reduxFormSitemodalForm?.objectId}/`, reduxModalSite)
            if (process.env.NODE_ENV === 'development') console.log(getUrl, 'getDetailData TemplateBlock796Site')
            axios({
                headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoadingData(false)
                    const newValues: any = {}
                    formFields.map((val: any) => {
                        newValues[val.name] = {
                            value: response.data[val.name]?.id || response.data[val.name],
                        }
                        return false
                    })
                    setFormValue!({
                        ...formValue!,
                        fields: newValues,
                    })
                })
                .catch((error) => {
                    setIsLoadingData(false)
                    axiosErrorHandlerSite({
                        apiUrl: getUrl,
                        component: 'TemplateBlock763Site',
                        dispatch,
                        error,
                        reduxAuth,
                        reduxModalSite,
                        reference: 'handleSubmit',
                    })
                })

        } catch (error) {
            setIsLoadingData(false)
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock796Site',
                'getDetailData',
            ))
        }
    }

    const formInfoObject = {
        id: object.id,
        detailId: detailId,
        fields: fields,
        formSubmitType: object.data_json?.formSubmitType,
        form_is_external: object.data_json?.form_is_external,
        form_is_newsletter: object.layout_type === 'e' ? true : undefined,
        form_is_subscription: object.data_json?.form_is_subscription,
        helper_text_position: object.data_json?.helper_text_position,
    }

    return (
        <React.Fragment>
            {fields.map((val2: any, j: number) => {
                const hiddenIf = val2?.data_json?.hidden_if
                const hiddenIfField = hiddenIf?.split('=')[0]
                const hiddenIfValue = hiddenIf?.split('=')[1]

                // @ts-ignore
                const hiddenIfFieldObj = formValue?.fields[hiddenIfField]
                const hiddenIfFieldObjValue = hiddenIfFieldObj?.value
                if (hiddenIfValue === 'false' && !hiddenIfFieldObjValue) {
                    return null
                }
                if (hiddenIfValue && hiddenIfValue.split('__')[0] === 'not' && !hiddenIfFieldObjValue) {
                    return null
                }
                if (hiddenIfFieldObj) {
                    const hiddenIfValueSplit = hiddenIfValue.split('__')
                    if (hiddenIfFieldObj.field_type === 'selectmultiple') {
                        // @ts-ignore
                        const hiddenIfFieldObjValueArrayString = hiddenIfFieldObjValue?.map((val: any) => val.id)
                        if (hiddenIfValueSplit.length === 1) {
                            if (hiddenIfFieldObjValueArrayString.includes(Number(hiddenIfValue))) {
                                return null
                            }
                        } else {
                            if (hiddenIfValueSplit[0] === 'not') {
                                if (!hiddenIfFieldObjValueArrayString.includes(Number(hiddenIfValueSplit[1]))) {
                                    return null
                                }
                            } else {
                                if (hiddenIfFieldObjValueArrayString.includes(Number(hiddenIfValueSplit[1]))) {
                                    return null
                                }
                            }
                        }
                    } else {
                        if (hiddenIfValueSplit.length === 1) {
                            if (`${hiddenIfFieldObjValue}` === `${hiddenIfValue}`) {
                                return null
                            }
                        } else {
                            if (hiddenIfValueSplit[0] === 'not') {
                                if (`${hiddenIfFieldObjValue}` !== `${hiddenIfValueSplit[1]}`) {
                                    return null
                                }
                            } else {
                                if (`${hiddenIfFieldObjValue}` === `${hiddenIfValueSplit[1]}`) {
                                    return null
                                }
                            }
                        }
                    }
                } else {
                    if (val2?.data_json?.hidden_default === 'true') {
                        return null
                    }
                }
                return (
                    <div
                        key={val2.name}
                        id={`${blockId}-${val2.name}`}
                        className={`template-block-796${reduxFormSiteparentContent === object.id ? ' active' : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                        style={val2.styles_796 ? getStylesNew(reduxModalSite, val2.styles_796, undefined) : stylesNew}
                    >
                        {val2.field_type === 'checkbox' && (
                            <div
                                className={`t-796-checkbox-wrap ${val2.field_type} ${deviceType}`}
                                style={{
                                    // @ts-ignore
                                    borderBottomStyle: getStylesToRetrieveNew(reduxModalSite, content[1]?.styles, undefined, 'borderStyle'),
                                    borderColor: getStylesToRetrieveNew(reduxModalSite, content[1]?.styles, undefined, 'borderColor'),
                                    borderWidth: getStylesToRetrieveNew(reduxModalSite, content[1]?.styles, undefined, 'borderWidth'),
                                }}
                            >
                                {content?.slice(0, 2).map((val) => {
                                    // @ts-ignore
                                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}-${val2.name}`
                                    return (
                                        <Component
                                            key={val.id}
                                            blockId={contentBlockId}
                                            content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                                            detailId={detailId}
                                            devId={devId}
                                            disableInfiniteScroll={true}
                                            formInfo={formInfoObject}
                                            formInputObj={val2}
                                            formValue={formValue}
                                            hasMore={false}
                                            inFormModal={inFormModal}
                                            inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                            isInDetailPage={isInDetailPage}
                                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                            mainParentId={mainParentId || object.id}
                                            object={val}
                                            onSearchNext={undefined}
                                            pageSlug={pageSlug}
                                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                            parentStyles={object.styles}
                                            setFormValue={setFormValue}
                                            styles={val.styles}
                                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                                        />
                                    )
                                })}
                            </div>
                        )}
                        {val2.field_type !== 'checkbox' && (
                            content?.slice(0, 2).map((val) => {
                                // @ts-ignore
                                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}-${val2.name}`
                                return (
                                    <Component
                                        key={val.id}
                                        blockId={contentBlockId}
                                        content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                                        detailId={detailId}
                                        devId={devId}
                                        disableInfiniteScroll={true}
                                        formInfo={formInfoObject}
                                        formInputObj={val2}
                                        formValue={formValue}
                                        hasMore={false}
                                        inFormModal={inFormModal}
                                        inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                                        index={j + 1}
                                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                        isInDetailPage={isInDetailPage}
                                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                        mainParentId={mainParentId || object.id}
                                        object={val}
                                        onSearchNext={undefined}
                                        pageSlug={pageSlug}
                                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                        parentStyles={object.styles}
                                        setFormValue={setFormValue}
                                        styles={val.styles}
                                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                                    />
                                )
                            })
                        )}
                    </div>
                )
            })}
            {content?.map((val, i) => {
                if (i <= 1) return null
                // @ts-ignore
                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                return (
                    <Component
                        key={val.id}
                        blockId={contentBlockId}
                        content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                        detailId={detailId}
                        devId={devId}
                        disableInfiniteScroll={true}
                        formInfo={formInfoObject}
                        formValue={formValue}
                        hasMore={false}
                        inFormModal={inFormModal}
                        inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                        isInDetailPage={isInDetailPage}
                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                        mainParentId={mainParentId || object.id}
                        object={val}
                        onSearchNext={undefined}
                        pageSlug={pageSlug}
                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                        parentStyles={object.styles}
                        setFormValue={setFormValue}
                        styles={val.styles}
                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                    />
                )
            })}
            {formValue?.errors?.main_error && (
                <IonAlert
                    buttons={['OK']}
                    isOpen
                    header={formValue.errors.main_error}
                />
            )}
            {formValue?.helpers.isSuccess && (
                <Snackbar
                    action={
                        <Button
                            onClick={() => setFormValue({ ...formValue, helpers: { ...formValue.helpers, isSuccess: false } })}
                            size='small'
                        >
                            {reduxText[8353]}
                        </Button>
                    }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                    className={`t-796-snackbar ${deviceType}`}
                    message={reduxText[7500]}
                    onClose={() => setFormValue({ ...formValue, helpers: { ...formValue.helpers, isSuccess: false } })}
                    open
                />
            )}
            {(formValue?.helpers.isLoading || isLoadingData) && (
                <LoaderSite isOpen />
            )}
        </React.Fragment>
    )
})
