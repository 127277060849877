// react components
import React, {
    useEffect,
    useState,
} from 'react'
import Linkify from 'react-linkify'
import Input from 'react-phone-number-input/input'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormModalFormShowSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template823SiteSerializer,
} from 'serializers/site'

// services
import {
    getDateWithTypeSite,
    getStylesNew,
} from 'services'

// styles
import 'react-phone-number-input/style.css'

// props
type TemplateBlock823SiteProps = {
    blockId: string
    content: Template823SiteSerializer | undefined
    isEditHovered: boolean
    object: PortfolioPageContentListSiteSerializer
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock823Site: React.FC<TemplateBlock823SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    object,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        tableStyles: CustomCSSProperties | undefined,
        theadStyles: CustomCSSProperties | undefined,
        theadValueStyles: CustomCSSProperties | undefined,
        tdStyles: CustomCSSProperties | undefined,
        tdValueStyles: CustomCSSProperties | undefined,
        // @ts-ignore
    } | undefined>(applyStyles())

    useEffect(() => {
        // @ts-ignore
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('TemplateBlock823Site', content)
            const newStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                tableStyles: {
                    minWidth: newStyles?.tableMinWidth,
                    width: newStyles?.tableWidth,
                },
                theadStyles: {
                    backgroundColor: newStyles?.theadBackgroundColor,
                    borderColor: newStyles?.theadBorderColor,
                    borderStyle: newStyles?.theadBorderStyle,
                    borderWidth: newStyles?.theadBorderWidth,
                },
                theadValueStyles: {
                    color: newStyles?.theadColor,
                    fontSize: newStyles?.theadFontSize,
                    fontStyle: newStyles?.theadFontStyle,
                    fontWeight: newStyles?.theadFontWeight,
                    letterSpacing: newStyles?.theadLetterSpacing,
                    lineHeight: newStyles?.theadLineHeight,
                    padding: newStyles?.theadPadding,
                    textAlign: newStyles?.theadTextAlign,
                    textTransform: newStyles?.theadTextTransform,
                },
                tdStyles: {
                    backgroundColor: newStyles?.tdBackgroundColor,
                    borderColor: newStyles?.tdBorderColor,
                    borderStyle: newStyles?.tdBorderStyle,
                    borderWidth: newStyles?.tdBorderWidth,
                },
                tdValueStyles: {
                    color: newStyles?.tdColor,
                    fontSize: newStyles?.tdFontSize,
                    fontStyle: newStyles?.tdFontStyle,
                    fontWeight: newStyles?.tdFontWeight,
                    letterSpacing: newStyles?.tdLetterSpacing,
                    lineHeight: newStyles?.tdLineHeight,
                    padding: newStyles?.tdPadding,
                    textAlign: newStyles?.tdTextAlign,
                    textTransform: newStyles?.tdTextTransform,
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock823Site',
                'applyStyles',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-823${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <table
                className={`t-823-table ${deviceType}`}
                style={stylesNew?.tableStyles}
            >
                <thead>
                    <tr className={`t-823-thead-tr ${deviceType}`}>
                        {(content?.data?.[0]?.absolute_site_url || content?.data?.[0]?.can_edit) && (
                            <th
                                className={`t-823-thead-th ${deviceType}`}
                                style={stylesNew?.theadStyles}
                            />
                        )}
                        {content?.columns?.map(column => {
                            return (
                                <th
                                    key={column.key}
                                    className={`t-823-thead-th ${deviceType}`}
                                    style={stylesNew?.theadStyles}
                                >
                                    <div className={`t-823-thead-th-div-wrap ${deviceType}`}>
                                        <div
                                            className={`t-823-thead-th-div ${deviceType}`}
                                            style={stylesNew?.theadValueStyles}
                                        >
                                            {column.title}
                                        </div>
                                        {/* <div className={`th-filter-div ${deviceType}`}>
                                        <TableHeaderAction
                                            column={column}
                                            edit={false}
                                            fieldType={fieldOptions.fieldType}
                                            filterValues={filterValues}
                                            inModal={inModal}
                                            setFilter={setFilter}
                                            setShowActionPopover={setShowActionPopover}
                                            showActionPopover={showActionPopover}
                                        />
                                    </div> */}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {content?.data?.map(row => {
                        return (
                            <tr
                                key={row.id}
                                className={`t-823-tbody-tr ${deviceType}`}
                            >
                                {(content?.data?.[0]?.absolute_site_url || content?.data?.[0]?.can_edit) && (
                                    <td
                                        className={`t-823-tbody-td ${deviceType}`}
                                        style={stylesNew?.tdStyles}
                                    >
                                        <div className={`t-823-actions ${deviceType}`}>
                                            {row.absolute_site_url && (
                                                <LinkHelperSite
                                                    action='generic_link'
                                                    onClick={() => undefined}
                                                    to={row.absolute_site_url}
                                                >
                                                    <i className={`main-icon mo-new-icon-eye-solid ${deviceType}`} />
                                                </LinkHelperSite>
                                            )}
                                            {row.can_edit && (
                                                <LinkHelperSite
                                                    action=''
                                                    onClick={() => dispatch(reduxFormModalFormShowSite(true, undefined, content.content_type_label_model, row.id))}
                                                    to={undefined}
                                                >
                                                    <i className={`main-icon mo-new-icon-pencil-alt-solid ${deviceType}`} />
                                                </LinkHelperSite>
                                            )}
                                        </div>
                                    </td>
                                )}
                                {content?.columns?.map(column => {
                                    // @ts-ignore
                                    const value = row[column.key]
                                    switch (column.field_type) {
                                        case 'BooleanField':
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <i
                                                        className={`t-823-value boolean ${value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'} ${deviceType}`}
                                                        style={stylesNew?.tdValueStyles}
                                                    />
                                                </td>
                                            )
                                        case 'DateField':
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <p
                                                        className={`t-823-value ${deviceType}`}
                                                        style={stylesNew?.tdValueStyles}
                                                    >
                                                        {getDateWithTypeSite(reduxModalSite, value, '', 'DD-MM-YYYY')}
                                                    </p>
                                                </td>
                                            )
                                        case 'FileField':
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    {value
                                                        ? (
                                                            <a
                                                                className={`t-823-value ${deviceType}`}
                                                                href={value}
                                                                rel='noreferrer'
                                                                style={stylesNew?.tdValueStyles}
                                                                target='_blank'
                                                            >
                                                                <i className={`main-icon mo-new-icon-paperclip-solid ${deviceType}`} />
                                                            </a>
                                                        )
                                                        : null
                                                    }

                                                </td>
                                            )
                                        case 'ForeignKey':
                                            if (column.key?.split('__')[0] === 'custom_foreign_key_1') {
                                                if (row[column.key?.split('__')[0]]?.id === 48) {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            className={`t-823-tbody-td ${deviceType}`}
                                                            style={stylesNew?.tdStyles}
                                                        >
                                                            <i
                                                                className={`t-823-value boolean mo-new-icon-check-circle-solid ${deviceType}`}
                                                                style={stylesNew?.tdValueStyles}
                                                            />
                                                        </td>
                                                    )
                                                }
                                                if (row[column.key?.split('__')[0]]?.id === 49) {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            className={`t-823-tbody-td ${deviceType}`}
                                                            style={stylesNew?.tdStyles}
                                                        >
                                                            <i
                                                                className={`t-823-value boolean mo-new-icon-times-solid ${deviceType}`}
                                                                style={stylesNew?.tdValueStyles}
                                                            />
                                                        </td>
                                                    )
                                                }
                                            }
                                            if (column.key?.split('__')[0] === 'custom_foreign_key_2') {
                                                if (row[column.key?.split('__')[0]]?.id === 46) {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            className={`t-823-tbody-td ${deviceType}`}
                                                            style={stylesNew?.tdStyles}
                                                        >
                                                            <i
                                                                className={`t-823-value boolean mo-new-icon-check-circle-solid ${deviceType}`}
                                                                style={stylesNew?.tdValueStyles}
                                                            />
                                                        </td>
                                                    )
                                                }
                                                if (row[column.key?.split('__')[0]]?.id === 47) {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            className={`t-823-tbody-td ${deviceType}`}
                                                            style={stylesNew?.tdStyles}
                                                        >
                                                            <i
                                                                className={`t-823-value boolean mo-new-icon-times-solid ${deviceType}`}
                                                                style={stylesNew?.tdValueStyles}
                                                            />
                                                        </td>
                                                    )
                                                }
                                            }
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <p
                                                        className={`t-823-value ${deviceType}`}
                                                        style={stylesNew?.tdValueStyles}
                                                    >
                                                        {/* @ts-ignore */}
                                                        {row[column.key?.split('__')[0]]?.[column.key?.split('__')[1]]}
                                                    </p>
                                                </td>
                                            )
                                        case 'ManyToManyField':
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <p
                                                        className={`t-823-value ${deviceType}`}
                                                        style={stylesNew?.tdValueStyles}
                                                    >
                                                        {/* @ts-ignore */}
                                                        {row[column.key?.split('__')[0]]?.map((item: any) => item.name).join(', ')}
                                                    </p>
                                                </td>
                                            )
                                        case 'PhoneNumberField':
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <p
                                                        className={`t-823-value ${deviceType}`}
                                                        style={stylesNew?.tdValueStyles}
                                                    >
                                                        <Input
                                                            className={`phonenumber ${deviceType}`}
                                                            disabled
                                                            onChange={() => undefined}
                                                            value={value}
                                                        />
                                                    </p>
                                                </td>
                                            )
                                        default:
                                            return (
                                                <td
                                                    key={column.key}
                                                    className={`t-823-tbody-td ${deviceType}`}
                                                    style={stylesNew?.tdStyles}
                                                >
                                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                                        <p
                                                            className={`t-823-value ${deviceType}`}
                                                            style={stylesNew?.tdValueStyles}
                                                        >
                                                            {value}
                                                        </p>
                                                    </Linkify>
                                                </td>
                                            )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
})
