// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    GoogleLogin,
} from 'react-google-login'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    GOOGLE_CLIENT_ID_SITE,
    defaultReduxState,
    reduxAuthGoogleSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// props
type GoogleLoginButtonSiteProps = {
    setIsLoading: React.Dispatch<boolean>
    signIn?: boolean
}

// main
export const GoogleLoginButtonSite: React.FC<GoogleLoginButtonSiteProps> = React.memo(({
    setIsLoading,
    signIn,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [errors, setErrors] = useState<any>()

    useEffect(() => {
        if (location.hash) {
            const param = 'id_token'
            const url = new URL(window.location.href.replace('#', '?'))
            const id_token = url.searchParams && url.searchParams.get(param)
            if (id_token) {
                // @ts-ignore
                dispatch(reduxAuthGoogleSite(
                    id_token,
                    id_token, // TODO
                    reduxAuth,
                    reduxModalSite,
                    dispatch,
                    setIsLoading,
                    reduxCacheSiteportfolioid!,
                    setErrors,
                ))
            }
        }
    }, [
        location.hash,
    ])

    function googleSignIn(response: any) {
        try {
            if (response.error === 'idpiframe_initialization_failed') return
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) googleSignIn')
        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) googleSignIn')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'GoogleLoginButtonSite',
                'googleSignIn',
                true,
            ))
        }
    }

    return (
        <div className='google-login-block-site'>
            <GoogleLogin
                className='glbs-button'
                clientId={GOOGLE_CLIENT_ID_SITE}
                onFailure={(e) => googleSignIn(e)}
                onSuccess={(e) => googleSignIn(e)}
                uxMode='redirect'
            >
                {signIn ? reduxText[8423] : reduxText[8530]}
            </GoogleLogin>
            {errors && (
                <span className='glbs-error'>{errors.response?.data?.username || errors.response?.data || reduxText[8420]}</span>
            )}
        </div>
    )
})
