// react components
import React from 'react'
import {
    IonApp,
    IonRouterOutlet,
    setupIonicReact,
} from '@ionic/react'
import {
    IonReactRouter,
} from '@ionic/react-router'
import {
    ThemeProvider,
    createTheme,
} from '@mui/material'
import {
    Provider,
} from 'react-redux'
import {
    Route,
} from 'react-router-dom'

// components
import {
    EcPlayerSite,
    ErrorAlertSite,
    ErrorBoundarySite,
    ErrorEventHandlerSite,
    FormSuccessModalSite,
    FormSuccessToastSite,
    GlobalHelperSite,
    LightboxSite,
    MatomoTrackerSite,
    SlaaskSite,
    UserWaySite,
} from 'components'

// data
import {
    view_url_portfolio_home_site,
} from 'data'

// pages
import {
    CartPopUpBlockSite,
    ConsentBlockSite,
    DetailModalWrapperSite,
    NavbarWrapperSite,
    NotFoundWrapperSite,
    Param0WrapperSite,
    Param1WrapperSite,
    Param2WrapperSite,
    Param3WrapperSite,
    Param4WrapperSite,
    Param5WrapperSite,
} from 'pages'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import './@static/site/index.scss'

/* Vendor css */
import 'leaflet/dist/leaflet.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-image-lightbox/style.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import configureStore from './data/site/mo_store'

// Redux Constants
const store = configureStore()

// MUI style override
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#181818',
        },
        secondary: {
            main: '#8ea1ff',
        },
    },
    typography: {
        fontFamily: 'FuturaPT',
    },
    zIndex: {
        snackbar: 99999,
    }
})

setupIonicReact({
    swipeBackEnabled: false,
    mode: 'ios',
    spinner: 'crescent',
})

// main
export const App: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ThemeProvider theme={darkTheme}>
                    <ErrorBoundarySite>
                        <NavbarWrapperSite />
                        <IonRouterOutlet animated={window.innerWidth <= 767.99}>
                            <Route exact path={`${view_url_portfolio_home_site}:devId/`} component={Param0WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/`} component={Param1WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/`} component={Param2WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/`} component={Param3WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/`} component={Param4WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/`} component={Param5WrapperSite} />

                            {/* Handle not match url because Redirect is bugging and prevent going to other link */}
                            {/* <Redirect to='/' /> */}
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/:param6/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/:param6/:param7/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/:param9/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:devId/:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/:param9/:param10/`} component={NotFoundWrapperSite} />
                        </IonRouterOutlet>
                        <CartPopUpBlockSite />
                        <ConsentBlockSite />
                        <DetailModalWrapperSite />
                        <EcPlayerSite />
                        <ErrorEventHandlerSite />
                        <FormSuccessModalSite />
                        <FormSuccessToastSite />
                        <GlobalHelperSite />
                        <LightboxSite />
                        <MatomoTrackerSite />
                        <SlaaskSite />
                        <UserWaySite />
                    </ErrorBoundarySite>
                </ThemeProvider>
            </IonReactRouter>
            <ErrorAlertSite />
        </IonApp>
    </Provider>
)

export const AppProd: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ThemeProvider theme={darkTheme}>
                    <ErrorBoundarySite>
                        <NavbarWrapperSite />
                        <IonRouterOutlet animated={window.innerWidth <= 767.99}>
                            <Route exact path={`${view_url_portfolio_home_site}`} component={Param0WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/`} component={Param1WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/`} component={Param2WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/`} component={Param3WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/`} component={Param4WrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/`} component={Param5WrapperSite} />

                            {/* Handle not match url because Redirect is bugging and prevent going to other link */}
                            {/* <Redirect to='/' /> */}
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/:param6/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/:param6/:param7/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/:param9/`} component={NotFoundWrapperSite} />
                            <Route exact path={`${view_url_portfolio_home_site}:param1/:param2/:param3/:param4/:param5/:param6/:param7/:param8/:param9/:param10/`} component={NotFoundWrapperSite} />
                        </IonRouterOutlet>
                        <CartPopUpBlockSite />
                        <ConsentBlockSite />
                        <DetailModalWrapperSite />
                        <EcPlayerSite />
                        <ErrorEventHandlerSite />
                        <FormSuccessModalSite />
                        <FormSuccessToastSite />
                        <GlobalHelperSite />
                        <LightboxSite />
                        <MatomoTrackerSite />
                        <SlaaskSite />
                        <UserWaySite />
                    </ErrorBoundarySite>
                </ThemeProvider>
            </IonReactRouter>
            <ErrorAlertSite />
        </IonApp>
    </Provider>
)
