// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template786SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock786SiteProps = {
    blockId: string
    content: Template786SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock786Site: React.FC<TemplateBlock786SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        seperation: string | undefined,
        styles: CustomCSSProperties | undefined,
        stylesActive: {
            background: string | undefined,
            borderColor: string | undefined,
            color: string | undefined,
            fontWeight: string | undefined,
            textDecorationColor: string | undefined,
            textDecorationLine: string | undefined,
            textDecorationStyle: string | undefined,
            textDecorationThickness: string | undefined,
            textUnderlineOffset: string | undefined,
        } | undefined,
        transition: string | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                seperation: aStyles?.seperation,
                styles: aStyles,
                stylesActive: !object.action ? undefined : {
                    background: aStyles?.hoverBackground,
                    borderColor: aStyles?.hoverBorderColor,
                    color: aStyles?.hoverColor,
                    fontWeight: aStyles?.hoverFontWeight,
                    opacity: aStyles?.hoverOpacity,
                    textDecorationColor: aStyles?.hoverTextDecorationColor,
                    textDecorationLine: aStyles?.hoverTextDecorationLine,
                    textDecorationStyle: aStyles?.hoverTextDecorationStyle,
                    textDecorationThickness: aStyles?.hoverTextDecorationThickness,
                    textUnderlineOffset: aStyles?.hoverTextUnderlineOffset,
                },
                transition: `${object.action ? ' transition' : ''}`,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock786Site',
                'applyStyles',
            ))
        }
    }

    return (
        <React.Fragment>
            {content?.profile && (
                <React.Fragment>
                    <LinkHelperSite
                        id={blockId}
                        action={object.action}
                        className={`template-block-786${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                        onClick={() => content.profile ? onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content.profile, object, [content.profile]) : undefined}
                        style={stylesNew?.styles}
                        // @ts-ignore
                        styleActive={stylesNew?.stylesActive}
                        to={content.profile.absolute_site_url}
                    >
                        <p>{content.profile.name}</p>
                    </LinkHelperSite>
                    {(content.team!.length > 0) && (
                        <span
                            className={`template-block-786 ${deviceType}`}
                            style={stylesNew?.styles}
                        >
                            {` ${stylesNew?.seperation} `}
                        </span>
                    )}
                </React.Fragment>
            )}
            {content?.team?.map((val, i) => (
                <React.Fragment key={val.id}>
                    <LinkHelperSite
                        id={blockId}
                        action={object.action}
                        className={`template-block-786${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
                        onClick={() => content.team ? onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, val, object, content.team) : undefined}
                        style={stylesNew?.styles}
                        // @ts-ignore
                        styleActive={stylesNew?.stylesActive}
                        to={val.absolute_site_url}
                    >
                        <p>{val.name}</p>
                    </LinkHelperSite>
                    {stylesNew?.seperation && i < (content.team!.length - 1) && (
                        <span
                            className={`template-block-786 ${deviceType}`}
                            style={stylesNew?.styles}
                        >
                            {` ${stylesNew?.seperation} `}
                        </span>
                    )}
                </React.Fragment>
            ))}
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </React.Fragment>
    )
})
