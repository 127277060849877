// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    groupingIdsSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetActiveCollapsedIdSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

import * as templateBlock from './index'

// props
type TemplateBlock811SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    detailId: string | undefined
    devId: string | undefined
    inListIndex?: number
    isInComponent?: boolean
    isEditHovered: boolean
    isInDetailPage?: boolean
    isInVideoBlock?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock811Site: React.FC<TemplateBlock811SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    inListIndex,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInVideoBlock,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [isOpen, setIsOpen] = useState((object.data_json?.collapsedIsOpenId && (object.data_json?.collapsedIsOpenId === window.location.hash)) || false)
    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock811Site',
                'applyStyles',
            ))
        }
    }

    const dropdownTextComponent: PortfolioPageContentListSiteSerializer[] = []
    const componentsArray: PortfolioPageContentListSiteSerializer[] = []
    content?.map((val, i) => {
        if (i > 0) {
            componentsArray.push(val)
        } else {
            dropdownTextComponent.push(val)
        }
    })

    return (
        <div
            id={blockId}
            className={`template-block-811${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew}
        >
            <div
                className={`t-811-collapsed-header ${deviceType}`}
                onClick={() => {
                    setIsOpen(!isOpen)
                    if (!isOpen) dispatch(reduxModalSetActiveCollapsedIdSite(`${blockId}-${inListIndex}`))
                }}
            >
                {dropdownTextComponent.filter(obj => obj.active === true).map((val) => {
                    // @ts-ignore
                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                    return (
                        <Component
                            key={val.id}
                            blockId={contentBlockId}
                            collapseIsOpen={isOpen}
                            content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                            detailId={detailId}
                            devId={devId}
                            disableInfiniteScroll={true}
                            hasMore={false}
                            inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                            isInDetailPage={isInDetailPage}
                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                            mainParentId={mainParentId || object.id}
                            object={val}
                            onSearchNext={undefined}
                            pageSlug={pageSlug}
                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                            parentStyles={object.styles}
                            styles={val.styles}
                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                        />
                    )
                })}
            </div>
            {(((stylesNew?.collapseAutoClose === 'true') && isOpen && (reduxModalSite.activeCollapsedId === `${blockId}-${inListIndex}`)) || (!(stylesNew?.collapseAutoClose === 'true') && isOpen)) && (
                <div className='t-811-collapsed-content'>
                    {componentsArray.filter(obj => obj.active === true).map((val) => {
                        // @ts-ignore
                        const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                        const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                        return (
                            <Component
                                key={val.id}
                                blockId={contentBlockId}
                                content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                                detailId={detailId}
                                devId={devId}
                                disableInfiniteScroll={true}
                                hasMore={false}
                                inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                                isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                isInDetailPage={isInDetailPage}
                                isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                mainParentId={mainParentId || object.id}
                                object={val}
                                onSearchNext={undefined}
                                pageSlug={pageSlug}
                                parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                parentDropdownId={dropdownTextComponent[0]?.id}
                                parentStyles={object.styles}
                                styles={val.styles}
                                stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
})
