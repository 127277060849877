// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
    TemplateBlock799GeoJsonSite,
    TemplateBlock799GoogleSite,
    TemplateBlock799LeafletSite,
    TemplateBlock799SvgSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template799SiteSerializer,
} from 'serializers/site'

// services
import {
    getModalAbsoluteUrlSite,
    getStylesNew,
    onClickIsModalSite,
} from 'services'

// props
type TemplateBlock799SiteProps = {
    blockId: string
    isEditHovered: boolean
    content: Template799SiteSerializer | Template799SiteSerializer[] | undefined
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock799Site: React.FC<TemplateBlock799SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType
    // @ts-ignore
    const newContent: Template799SiteSerializer[] = Array.isArray(content) ? content : [content]

    const mapContainerRef = useRef<HTMLImageElement>(null)

    const [center, setCenter] = useState<{
        lat: number
        lng: number
    } | undefined>()
    const [markers, setMarkers] = useState<{
        extraInfo?: any
        lat: number
        lng: number
    }[] | undefined>()
    const [stylesNew, setStylesNew] = useState<{
        styles: CustomCSSProperties | undefined
        stylesMap: {
            leafletClusterColor: string | undefined
            leafletMarkerColor1: string | undefined
            leafletMarkerColor2: string | undefined
            leafletMarkerStyle: string | undefined
            mapProvider: string | undefined
            mapStyles: string | undefined
            mapZoom: string | undefined
        }
    } | undefined>(applyStyles())
    const [mapContainerHeight, setMapContainerHeight] = useState<number>(0)
    const [mapContainerIsLoaded, setMapContainerIsLoaded] = useState(false)
    const [mapContainerWidth, setMapContainerWidth] = useState<number>(0)

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        getMarkers()
    }, [
        content,
    ])

    useEffect(() => {
        if (['geojson', 'svg'].includes(stylesNew?.stylesMap.mapProvider!)) {
            setMapContainerDimension()
        }
    }, [
        stylesNew,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                styles: aStyles,
                stylesMap: {
                    leafletClusterColor: aStyles?.leafletClusterColor,
                    leafletMarkerColor1: aStyles?.leafletMarkerColor1,
                    leafletMarkerColor2: aStyles?.leafletMarkerColor2,
                    leafletMarkerStyle: aStyles?.leafletMarkerStyle,
                    mapProvider: aStyles?.mapProvider,
                    mapStyles: aStyles?.mapStyles,
                    mapZoom: aStyles?.mapZoom,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799Site',
                'applyStyles',
            ))
        }
    }

    function getMarkers() {
        try {
            const markersArray: {
                extraInfo?: any
                lat: number
                lng: number
            }[] = []
            newContent?.map((item) => {
                if (item?.address_json) {
                    item.address_json.map((val, i) => {
                        if (val.latValue && val.lngValue) {
                            markersArray.push({
                                extraInfo: item,
                                lat: Number(val.latValue),
                                lng: Number(val.lngValue),
                            })
                        }
                    })
                }
            })
            setMarkers(markersArray)
            let centerLat = 48.8587741
            let centerLng = 2.2069771
            if (markersArray.length === 1) {
                centerLat = Number(markersArray[0].lat)
                centerLng = Number(markersArray[0].lng)
            } else if (markersArray.length > 1) {
                let lat_min = markersArray[0].lat || 0
                let lat_max = markersArray[0].lat || 0
                let lng_min = markersArray[0].lng || 0
                let lng_max = markersArray[0].lng || 0
                markersArray.map((val, i) => {
                    if (Number(val.lat) < lat_min) lat_min = Number(val.lat)
                    if (Number(val.lat) > lat_max) lat_max = Number(val.lat)
                    if (Number(val.lng) < lng_min) lng_min = Number(val.lng)
                    if (Number(val.lng) > lng_max) lng_max = Number(val.lng)
                })
                centerLat = (lat_max + lat_min) / 2
                centerLng = (lng_max + lng_min) / 2
            }
            setCenter({
                lat: centerLat,
                lng: centerLng,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock799Site',
                'getMarkers',
            ))
        }
    }

    function onMarkerClick(e: any) {
        if (object.action && newContent) {
            onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, e.extraInfo, { action: 'modal_link' }, newContent)
            // TODO dynamic
            history.push(((reduxFormSitenavigationMode === 'navPage') || (reduxCacheSiteportfolioid !== 630 && deviceType !== 'is-web')) ? (`${reduxModalSite.rootUrl}${e.extraInfo?.absolute_site_url}`) : getModalAbsoluteUrlSite(e.extraInfo?.absolute_site_url))
        }
    }

    function setMapContainerDimension() {
        try {
            if (mapContainerRef?.current) {
                setMapContainerHeight(mapContainerRef.current.clientHeight)
                setMapContainerWidth(mapContainerRef.current.clientWidth)
                setMapContainerIsLoaded(true)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock782Site',
                'setMapContainerDimension',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-799${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            ref={mapContainerRef}
            style={stylesNew?.styles}
        >
            {(!stylesNew?.stylesMap.mapProvider || stylesNew?.stylesMap.mapProvider === 'google') && (
                <TemplateBlock799GoogleSite
                    center={center!}
                    icon={newContent?.[0]?.icon}
                    markers={markers}
                    onMarkerClick={onMarkerClick}
                    stylesMap={stylesNew?.stylesMap}
                    zoom={stylesNew?.stylesMap.mapZoom ? Number(stylesNew?.stylesMap.mapZoom) : 8}
                />
            )}
            {stylesNew?.stylesMap.mapProvider === 'geojson' && mapContainerIsLoaded && (
                <TemplateBlock799GeoJsonSite
                    height={mapContainerHeight}
                    markers={markers}
                    object={object}
                    onMarkerClick={onMarkerClick}
                    styles={stylesNew?.styles}
                    width={mapContainerWidth}
                />
            )}
            {stylesNew?.stylesMap.mapProvider === 'leaflet' && (
                <TemplateBlock799LeafletSite
                    center={center!}
                    icon={newContent?.[0]?.icon}
                    markers={markers}
                    onMarkerClick={onMarkerClick}
                    stylesMap={stylesNew?.stylesMap}
                    zoom={stylesNew?.stylesMap.mapZoom ? Number(stylesNew?.stylesMap.mapZoom) : 8}
                />
            )}
            {stylesNew?.stylesMap.mapProvider === 'svg' && mapContainerIsLoaded && (
                <TemplateBlock799SvgSite
                    height={mapContainerHeight}
                    markers={markers}
                    object={object}
                    onMarkerClick={onMarkerClick}
                    styles={stylesNew?.styles}
                    width={mapContainerWidth}
                />
            )}
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
