// react components
import React, {
    useEffect,
    useState,
} from 'react'
import Linkify from 'react-linkify'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    // EditBlockSite,
    TemplateBlock797CheckboxMultipleSite,
    TemplateBlock797CheckboxSite,
    TemplateBlock797DateTimeSite,
    TemplateBlock797ImageSite,
    TemplateBlock797InputSite,
    TemplateBlock797LocationSite,
    TemplateBlock797PhoneNumberSite,
    TemplateBlock797SelectNewSite,
    TemplateBlock797SelectSite,
    TemplateBlock797TextAreaSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock797SiteProps = {
    blockId: string
    formInfo: formInfoTypeSite
    formInputObj?: FormFieldSerializer
    formValue: formValueTypeSite
    inFormModal?: boolean
    index: number
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock797Site: React.FC<TemplateBlock797SiteProps> = React.memo(({
    blockId,
    formInfo,
    formInputObj,
    formValue,
    inFormModal,
    index,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        const defaultValue = parseQuerySite(location.search)?.[`defaultFields_${formInputObj?.name}`]
        if (defaultValue) {
            setFormValue!({
                ...formValue!,
                fields: {
                    ...formValue!.fields,
                    [formInputObj?.name!]: {
                        field_type: formInputObj?.field_type,
                        key: formInputObj?.name,
                        label: formInputObj?.label,
                        stripe_field_type: formInputObj?.stripe_field_type,
                        value: defaultValue,
                    },
                },
            })
        }
    }, [])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797Site',
                'applyStyles',
            ))
        }
    }

    function handleInputChange(name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) {
        try {
            let valueToReturn: any = value
            if (formInputObj?.field_type === 'checkboxmultiple') {
                // @ts-ignore
                const stringValueArray: string[] = formValue?.fields[name]?.value || []
                if (value) {
                    stringValueArray.push(stringValue!)
                    valueToReturn = stringValueArray
                } else {
                    const newStringValueArray: string[] = []
                    stringValueArray.map(val => {
                        if (val !== stringValue) newStringValueArray.push(val)
                    })
                    valueToReturn = newStringValueArray
                }
            }

            setFormValue!({
                ...formValue!,
                errors: {
                    [name]: '',
                },
                fields: {
                    ...formValue!.fields,
                    [name]: {
                        field_type: formInputObj?.field_type,
                        key: name,
                        label: label,
                        stripe_field_type: stripe_field_type,
                        value: valueToReturn,
                    },
                },
                helpers: {
                    ...formValue!.helpers,
                    buttonDisabled: false,
                    isSuccess: false,
                    isSuccessText: undefined,
                },
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797Site',
                'handleInputChange',
            ))
        }
    }

    return (
        <React.Fragment>
            {formInputObj?.helper_text && formInfo?.helper_text_position === 'after_label' && (
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                    <span
                        className='t-797-helper'
                        style={{
                            fontFamily: stylesNew?.fontFamily,
                            padding: '0 0 10px 0',
                        }}
                    >
                        {formInputObj.helper_text}
                    </span>
                </Linkify>
            )}
            {formInputObj?.helper_text_rich && formInfo?.helper_text_position === 'after_label' && (
                <span
                    className='t-797-helper'
                    dangerouslySetInnerHTML={{ __html: formInputObj.helper_text_rich }}
                    style={{
                        fontFamily: stylesNew?.fontFamily,
                        padding: '0 0 10px 0',
                    }}
                />
            )}
            {formInputObj?.field_type === 'checkbox' && (
                <TemplateBlock797CheckboxSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    // @ts-ignore
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'checkboxmultiple' && (
                <TemplateBlock797CheckboxMultipleSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInfo={formInfo}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                />
            )}
            {formInputObj?.field_type === 'datetime' && (
                <TemplateBlock797DateTimeSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    format='datetime'
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'fileinput' && (
                <TemplateBlock797ImageSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    pdfOk
                    stylesNew={stylesNew}
                    tabIndex={index}
                />
            )}
            {formInputObj?.field_type === 'location' && (
                <TemplateBlock797LocationSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'phonenumber' && (
                <TemplateBlock797PhoneNumberSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'select' && (
                <TemplateBlock797SelectSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInfo={formInfo}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    location={location}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'select2' && (
                <TemplateBlock797SelectNewSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInfo={formInfo}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'selectmultiple' && (
                <TemplateBlock797SelectNewSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInfo={formInfo}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    multi
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {formInputObj?.field_type === 'textarea' && (
                <TemplateBlock797TextAreaSite
                    blockId={blockId}
                    deviceType={deviceType}
                    formInputObj={formInputObj}
                    handleInputChange={handleInputChange}
                    isEditHovered={isEditHovered}
                    reduxModalSite={reduxModalSite}
                    stylesNew={stylesNew}
                    tabIndex={index}
                    value={formValue?.fields[formInputObj?.name!]?.value || ''}
                />
            )}
            {![
                'checkbox',
                'checkboxmultiple',
                'datetime',
                'fileinput',
                'location',
                'phonenumber',
                'select',
                'select2',
                'selectmultiple',
                'separator',
                'textarea',
            ].includes(formInputObj?.field_type!) && (
                    <TemplateBlock797InputSite
                        blockId={blockId}
                        deviceType={deviceType}
                        formInputObj={formInputObj}
                        handleInputChange={handleInputChange}
                        isEditHovered={isEditHovered}
                        maxLength={formInputObj?.data_json?.max_length}
                        reduxModalSite={reduxModalSite}
                        stylesNew={stylesNew}
                        tabIndex={index}
                        value={formValue?.fields[formInputObj?.name!]?.value || ''}
                    />
                )
            }
            {formInputObj?.helper_text && !formInfo?.helper_text_position && (
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                    <span
                        className='t-797-helper'
                        style={{
                            fontFamily: stylesNew?.fontFamily,
                        }}
                    >
                        {formInputObj.helper_text}
                    </span>
                </Linkify>
            )}
            {formInputObj?.helper_text_rich && !formInfo?.helper_text_position && (
                <span
                    className='t-797-helper'
                    dangerouslySetInnerHTML={{ __html: formInputObj.helper_text_rich }}
                    style={{
                        fontFamily: stylesNew?.fontFamily,
                    }}
                />
            )}
            {formValue?.errors?.[formInputObj?.name!] && (
                <span
                    className='t-797-error'
                    style={{
                        fontFamily: stylesNew?.fontFamily,
                    }}
                >
                    {formValue.errors[formInputObj!.name!]}
                </span>
            )}
            {/* <EditBlockSite
				// customPosition={positions}
				isInComponent={isInComponent}
				mainParentId={mainParentId || object.id!}
				object={object}
				parentArray={parentArray}
				parentStyles={parentStyles}
				// resetCustomPosition={() => setPositions('leave')}
			/> */}
        </React.Fragment>
    )
})
