// react components
import React, {
    useEffect,
    useState,
} from 'react'
import moment from 'moment'
import {
    Calendar,
    Culture,
    DateLocalizer,
    View,
    momentLocalizer,
} from 'react-big-calendar'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    LoaderSite,
} from 'components'

// data
import {
    defaultReduxState,
    languageIdObjectSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template812SiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getModalAbsoluteUrlSite,
    getPageContentDataSite,
    getStylesNew,
    onClickIsModalSite,
} from 'services'

const localizer = momentLocalizer(moment)

// props
type TemplateBlock812SiteProps = {
    blockId: string
    content: Template812SiteSerializer[] | undefined
    detailId: string | undefined
    devId: string | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock812Site: React.FC<TemplateBlock812SiteProps> = React.memo(({
    blockId,
    content,
    detailId,
    devId,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    pageSlug,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [calendarContent, setCalendarContent] = useState<Template812SiteSerializer[] | undefined>()
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(false)
    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setCleanData(content)
    }, [])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        moment.locale(languageIdObjectSite[reduxModalSite.languageId] || 'en')
    }, [
        reduxModalSite.languageId,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock812Site',
                'applyStyles',
            ))
        }
    }

    function setCleanData(data: Template812SiteSerializer[] | undefined) {
        try {
            const newData: Template812SiteSerializer[] = []
            data?.map(val => {
                // @ts-ignore
                if (val.start) val.start = parseDate(val.start)
                // @ts-ignore
                if (val.end) val.end = parseDate(val.end)
                newData.push(val)
            })
            setCalendarContent(newData)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock812Site',
                'setCleanData',
            ))
        }
    }

    function parseDate(val: string) {
        const date = new Date(val)
        // const timezoneOffset = date.getTimezoneOffset()
        // const offsetHours = timezoneOffset / 60
        // date.setHours(date.getHours() + offsetHours)
        return date
    }

    function onRangeChange(range: Date[] | { start: Date; end: Date; }, view?: View | undefined) {
        try {
            let range_start_date = undefined
            let range_end_date = undefined
            if (Array.isArray(range)) {
                if (range.length === 1) {
                    range_start_date = moment(range[0]).format()
                    range_end_date = moment(range[0]).add(1, 'd').format()
                } else {
                    range_start_date = moment(range[0]).format()
                    range_end_date = moment(range.at(-1)).format()
                }
            } else {
                range_start_date = moment(range.start).format()
                range_end_date = moment(range.end).format()
            }
            if (range_start_date && reduxCacheSite.portfolio) {
                getPageContentDataSite({
                    axiosCancelToken: undefined,
                    component: 'TemplateBlock812Site',
                    detailId,
                    devId,
                    dispatch,
                    errorStatus,
                    extraFilter: `content_id_extra~${object.id},range_start_date~${range_start_date},range_end_date~${range_end_date}`,
                    infiniteContentId: object.id,
                    location,
                    pageSlug: pageSlug || reduxCacheSite.portfolio.home_page_slug!,
                    portfolioObject: reduxCacheSite.portfolio,
                    reduxAuth,
                    reduxCacheSite,
                    reduxModalSite,
                    setErrorStatus,
                    setIsLoading,
                    setPageContentItems: (e: any) => setCleanData(e[0]?.content),
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock812Site',
                'onRangeChange',
            ))
        }
    }

    function onSelectEvent(e: any) {
        try {
            if (object.action && calendarContent) {
                onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, e, object, calendarContent)
                history.push(getModalAbsoluteUrlSite(e.absolute_site_url))
            } else if (e.absolute_site_url) {
                history.push(`${reduxModalSite.rootUrl || '/'}${e.absolute_site_url}`)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock812Site',
                'onSelectEvent',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-812${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <Calendar
                // dayPropGetter={(date) => {
                //     const style = {
                //         // backgroundColor: 'transparent',
                //         border: '1px solid #c4aef9',
                //     }
                //     return { style }
                // }}
                defaultView={stylesNew?.calendarDefaultView || 'month'}
                endAccessor='end'
                eventPropGetter={(event, start, end, isSelected) => {
                    const newStyle = {
                        backgroundColor: event.color || stylesNew?.calendarEventBackground,
                        border: 'none',
                        color: event.textColor || stylesNew?.calendarEventTextColor,
                        fontSize: stylesNew?.calendarEventFontSize,
                    }
                    return {
                        className: '',
                        style: newStyle
                    }
                }}
                events={calendarContent}
                formats={{
                    agendaHeaderFormat: (range: { start: Date, end: Date }, culture?: Culture, localizer?: DateLocalizer) => `${localizer?.format(range.start, 'DD/MM/YYYY', culture)} - ${localizer?.format(range.end, 'DD/MM/YYYY', culture)}`
                }}
                localizer={localizer}
                messages={{
                    agenda: reduxText[8511],
                    allDay: reduxText[8617],
                    date: reduxText[8618],
                    day: reduxText[8510],
                    event: reduxText[8619],
                    month: reduxText[8508],
                    next: reduxText[8514],
                    noEventsInRange: reduxText[8620],
                    previous: reduxText[8513],
                    showMore: (total) => `+${total} ${reduxText[8622]}`,
                    time: reduxText[8621],
                    today: reduxText[8512],
                    tomorrow: reduxText[8623],
                    week: reduxText[8509],
                    work_week: reduxText[8624],
                    yesterday: reduxText[8625],
                }}
                onRangeChange={onRangeChange}
                onSelectEvent={onSelectEvent}
                startAccessor='start'
                style={stylesNew}
                views={['month', 'week', 'day', 'agenda']}
            />
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
