// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
} from 'react-redux'
import Select from 'react-select'

// data
import {
    reduxModalStateSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
    formInfoTypeSite,
} from 'serializers/site'

// props
type TemplateBlock797SelectMultipleSiteProps = {
    blockId: string
    deviceType: 'is-mobile' | 'is-tablet' | 'is-web'
    formInfo: formInfoTypeSite
    formInputObj: FormFieldSerializer | undefined
    handleInputChange: (name: string, value: any, label: string, stripe_field_type: string | undefined, stringValue?: string) => void
    isEditHovered: boolean
    multi?: boolean
    reduxModalSite: reduxModalStateSite
    stylesNew: CustomCSSProperties | undefined
    tabIndex: number
    value: any
}

// main
export const TemplateBlock797SelectNewSite: React.FC<TemplateBlock797SelectMultipleSiteProps> = React.memo(({
    blockId,
    deviceType,
    formInfo,
    formInputObj,
    handleInputChange,
    isEditHovered,
    multi,
    reduxModalSite,
    stylesNew,
    tabIndex,
    value,
}) => {

    const dispatch = useDispatch()

    const [newValue, setNewValue] = useState<any>()

    useEffect(() => {
        if (multi) {
            setNewValue(value)
        } else {
            if (value?.id) {
                setNewValue(value)
            } else {
                setNewValue(formInputObj?.options?.find((obj) => `${(formInfo?.form_is_external || formInfo?.form_is_subscription || (formInfo?.formSubmitType && !['miscellanea_contact', 'miscellanea_mail'].includes(formInfo?.formSubmitType))) ? obj.id : obj.value}` === `${value}`) || null)
            }
        }
    }, [
        value,
    ])

    function onInputChange(e: any) {
        try {
            let toReturnValue = e
            if (!multi && e?.id) {
                toReturnValue = (formInfo?.form_is_external || formInfo?.form_is_subscription || (formInfo?.formSubmitType && !['miscellanea_contact', 'miscellanea_mail'].includes(formInfo?.formSubmitType))) ? e.id : e.value
            }
            if (multi && formInputObj?.data_json?.select_mutliple_max && e?.length > Number(formInputObj.data_json.select_mutliple_max)) {
                setNewValue(value)
                return
            }
            handleInputChange(formInputObj?.name!, toReturnValue, formInputObj?.label!, formInputObj?.stripe_field_type)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock797SelectNewSite',
                'onInputChange',
            ))
        }
    }

    // style={formInputObj?.styles_797 ? getStylesNew(reduxModalSite, formInputObj?.styles_797, undefined) : stylesNew}

    const customStyles = {
        clearIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 8px',
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            background: stylesNew?.background || 'transparent',
            border: stylesNew?.borderWidth ? 'undefined' : 'none',
            borderColor: stylesNew?.borderColor,
            borderRadius: stylesNew?.borderRadius,
            borderStyle: stylesNew?.borderStyle,
            borderWidth: stylesNew?.borderWidth,
            color: stylesNew?.color,
            fontFamily: stylesNew?.fontFamily,
            fontSize: stylesNew?.fontSize,
            padding: stylesNew?.padding,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            padding: '0 0 0 8px',
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            margin: 0,
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            margin: 0,
            padding: 0,
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            fontFamily: stylesNew?.fontFamily,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            ':hover': {
                ...provided[':hover'],
                cursor: 'pointer',
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: 'black',
            margin: 0,
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            padding: 0,
        }),
    }

    return (
        <Select
            id={blockId}
            className={`template-block-797${stylesNew?.placeholderColor ? ` placeholder-${stylesNew?.placeholderColor}` : ''}${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            // @ts-ignore
            getOptionLabel={option => (option.value || option.name)}
            getOptionValue={option => `${(formInfo?.form_is_external || formInfo?.form_is_subscription || (formInfo?.formSubmitType && !['miscellanea_contact', 'miscellanea_mail'].includes(formInfo?.formSubmitType))) ? option.id : (option.value || option.name)}`}
            isClearable={true}
            isMulti={multi}
            name={formInputObj?.name}
            onChange={onInputChange}
            options={formInputObj?.options}
            placeholder={formInputObj?.placeholder === 'true' ? formInputObj?.label : ''}
            styles={customStyles}
            tabIndex={tabIndex}
            value={newValue}
        />
    )
})
