// react components
import React from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessModalOpenSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// main
export const FormSuccessModalSite: React.FC = () => {

    const dispatch = useDispatch()
    const reduxFormSite = useSelector((state: defaultReduxState) => state.reduxFormSite)

    function onClose() {
        try {
            dispatch(dispatch(reduxFormSetIsSuccessModalOpenSite(false)))
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'FormSuccessModalSite',
                'onClose',
            ))
        }
    }

    if (reduxFormSite.isSuccessModalOpen) {

        return (
            <Dialog
                className='form-success-modal-site'
                classes={{ paper: 'mo-dialog-wrapper fix-width' }}
                onClose={onClose}
                open
            >
                <DialogTitle
                    className='mo-dialog-header'
                    style={{
                        backgroundColor: 'rgb(253, 110, 76)',
                        padding: '20px',
                    }}
                >
                    <div className='mo-dialog-header-close-wrap'>
                        <button
                            className='mo-dialog-header-close'
                            onClick={onClose}
                        >
                            <i className='main-icon mo-new-icon-times-solid' />
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        backgroundColor: 'rgb(253, 214, 100)',
                        padding: '20px',
                    }}
                >
                    <p
                        className='pre-line'
                        style={{
                            color: 'rgb(57, 62, 70)',
                            fontSize: '21px',
                            fontWeight: 800,
                            letterSpacing: '0.3px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                        }}
                    >
                        {reduxFormSite.isSuccessModalMessage}
                    </p>
                </DialogContent>
            </Dialog>
        )
    }

    return null
}
